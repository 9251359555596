.post {
  margin-top: 70px;
  margin-bottom: 70px;
  color:#6b6b6b;

  @media(max-width: 767px) {
    margin-top: 40px;
  }

  > .container {
    @media (min-width: 1200px) {
      width: 880px;
    }
  }
}

.post-title {
  font-size: 31px;
  font-weight: 400;
  color: #444;

  @media(max-width: 991px) {
    font-size: 28px;
  }

  @media(max-width: 767px) {
    font-size: 24px;
    line-height: 35px;
  }
}

.post-header {
  margin-top: 20px;
}

.post-author {
  color: #888;
  display: inline-block;

  img {
    border-radius: 100%;
    width: 55px;
    margin-right: 17px;
    position: relative;
    top: -2px;
  }

  span {
    color: #333;
  }
}

.post-date {
  display: inline-block;
  margin-left: 35px;
  color: #888;

  @media(max-width: 767px) {
    margin-left: 15px;
  }

  span {
    color: #333;
  }
}

.post-share {
  float: right;
  color: #888;

  @media(max-width: 767px) {
    float: none;
  }

  a {
    margin-left: 13px;
    position: relative;
    top: 3px;
    text-decoration: none;
  }

  i {
    font-size: 25px;

    &.ion-social-twitter {
      color: #1da1f2;
    }

    &.ion-social-facebook {
      color: #3b5998;
      font-size: 28px;
      margin-right: 3px;
    }

    &.ion-social-buffer {
      color: #2d2d2d;
    }
  }
}

.post-content {
  margin-top: 50px;

  @media(max-width: 767px) {
    margin-top: 30px;
  }

  h1, h2, h3, h4, h5 {
    margin: 45px 0 20px;
    font-size: 23px;
  }

  p {
    font-size: 16px;
    color: #6b6b6b;
    line-height: 29px;
    margin-bottom: 20px;

    @media(max-width: 991px) {
      font-size: 14px;
    }
  }

  .post-video {
    margin: 40px 0;

    iframe {
      width: 92%;
      display: block;
      margin: 0 auto;

      @media(max-width: 991px) {
        height: 350px;
      }

      @media(max-width: 767px) {
        width: 100%;
        height: 270px;
      }
    }
  }

  .post-gallery {
    margin: 45px -20px;

    @media(max-width: 991px) {
      margin: 45px 0;
    }

    img {
      @media(max-width: 767px) {
        margin-top: 20px !important;
      }
    }

    .zoomerang {
      display: block;
    }
  }

  .post-quote-wrapper {
    margin: 60px 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -25px;
      width: 3px;
      height: 100%;
      background: #e1e5ea;
    }

    .quote {
      color: #444;
      font-style: italic;
      font-size: 21px;
      line-height: 32px;
      width: 95%;

      @media(max-width: 767px) {
        font-size: 18px;
        width: 100%;
      }
    }

    .author {
      margin-top: 15px;
      font-size: 15px;
      color: #9097a0;
      padding-bottom: 10px;
    }
  }

  .post-side-image {
    margin: 50px 0;

    @media(max-width: 767px) {
      margin-bottom: 30px;
    }

    img {
      max-width: 315px;
      float: left;
      margin-left: -120px;
      margin-right: 40px;

      @media(max-width: 767px) {
        max-width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }

    p {
      margin-top: 20px;
    }
  }
}

.post-subscribe {
  border-top: 1px solid #ececec;
  margin-top: 80px;
  padding-top: 40px;
  margin-bottom: 100px;
  text-align: center;

  h3 {
    font-size: 19px;
  }

  form {
    margin-top: 30px;

    input {
      outline: none;
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid #d8d8d8;
      position: relative;
      top: 1px;
      width: 230px;
      font-size: 16px;
      margin-right: 20px;


      @media(max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }

      &:focus {
        border-color: #333;
      }
    }
  }
}
