.typed-text{
  position    : relative;
  line-height : 1.5em;


  #first{
    -webkit-animation: fadein 4s;
    -moz-animation: fadein 4s;
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  @media (max-width: 1250px) {
    //height:280px;
    //font : 300 40px/1.667 "Open Sans",sans-serif;

    #first{
      display:block;
    }
  }
  @media (max-width: 770px) {
    //height:380px;
    //font : 300 40px/1.667 "Open Sans",sans-serif;

    #first{
      display:block;
    }

  }


  & .typed-cursor{
    opacity           : 1;

  }

  @keyframes blink{
    0% { opacity:1; }

    50% { opacity:0; }

    100% { opacity:1; }
  }

  @-webkit-keyframes blink{
    0% { opacity:1; }

    50% { opacity:0; }

    100% { opacity:1; }
  }

  @-moz-keyframes blink{
    0% { opacity:1; }

    50% { opacity:0; }

    100% { opacity:1; }
  }

  & .user-caret{
    position      : relative;
    top           : -2.2em;
    left          : -1em;

    //overflow      : hidden;
    height        : 20px;
    padding       : 6px;

    background    : #000;
    font-size     : 12px;
    font-weight   : 600;
    color         : white;
    line-height   : 16px;
    text-align    : center;
    text-overflow : ellipsis;
    border-color  : #000;
    white-space   : nowrap;
    &.red{
      background   : red;
      border-color : red;
    }

    &.green{
      background   : green;
      border-color : green;
    }

    &.yellow{
      background   : yellow;
      border-color : yellow;
    }


  }

  .user-caret-label {
    overflow      : hidden;

    font-size     : 12px;
    font-weight   : 600;
    //color         : #FFFFFF;
    color         : inherit;
    line-height   : 16px;
    text-align    : center;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }

  .user-caret-marker {
    height: 32px;
    color: inherit;
    border: 1px solid transparent;
    &.red{
      border-color : red;
    }

    &.green{
      border-color : green;
    }

    &.yellow{
      border-color : yellow;
    }
  }
}

