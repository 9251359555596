.pricing-charts-header section{
  padding-top: 100px !important;

}

.pricing-plan-api{
  padding-top: 50px;

  h1{
    color:#555;
    font-size: 3.6rem;
    text-align: center;
    line-height: 1.5;
    text-decoration: underline;
    text-underline-position: under;

  }

  @media(max-width: 767px) {
    h1{
      margin-top: 50px;
      font-size: 3rem;
    }
  }
}

.pricing-charts-integrations {
  padding-top: 100px;

  h1{
    color:#555;
    font-size: 3.6rem;
    text-align: center;
    line-height: 2;
    text-decoration: underline;
    text-underline-position: under;

  }

  @media(max-width: 767px) {
    h1{
      margin-top: 50px;
      font-size: 3rem;
    }
  }
}


.pricing-charts-header {
  background:rgb(71, 105, 145);

  h1 {
    color: #fff;
    font-size: 48px;
    font-weight: 300;
    text-align: center;
    
  }

  h3{
    padding-top: 10px;
    font-size: 24px !important;

  }

  @media(max-width: 767px) {
    h1{
      font-size: 3.6rem !important;
    }
    h3{
      font-size: 2.2rem !important;
    }
  }
}

.pricing-plans{
  > .container{
    padding-bottom: 50px;
  }

  .plans {
    @media(max-width: 767px) {
      margin-top: 50px;

      .plan {

        div {
          top: 0px !important;
        }

        small{
          top: 0px !important;
          display: block !important;
          font-size: 16px !important;

        }

        .choose{
          top: 10px !important;

        }
      }
    }

    .plan{
      small{
        font-size: 16px;
        font-weight: 400;
        position: relative;
        top: -2px;

      }
      .lite {
        display: none;

        &.active{
          display:block;
          opacity: 1;
        }
      }

      .standard{
        display:none;

        &.active{
          display: block;
          opacity: 1;
        }
      }

      div{
        transition: unset;
        color: #5b7386 !important;
        position: relative;
        top: -7px;
      }

      .sessions{
        width: 25% !important;
        font-size: 28px !important;
        text-align: left !important;
        @media(max-width: 767px) {
          font-size: 22px !important;
        }
      }

      .users{
        width:20% !important;
        text-align: center !important;
        font-size: 28px !important;
        @media(max-width: 767px) {
          font-size: 22px !important;
        }
      }

      .price {
        width:25% !important;
        text-align: center !important;
        @media(max-width: 767px) {
          font-size: 22px !important;
        }
      }
    }
  } 
}
.pricing-plan-tabs {
  margin-top: 55px;
  text-align: center;

  .tabs {
    margin: 0 auto;
    width: 330px;
  }

  .tab {
    display: inline-block;
    width: 165px;
    float: left;
    border: 1px solid #c7c7c7;
    padding: 13px 0;
    background: #f9f9f9;
    color: #8F8B8B;
    cursor: pointer;
    position: relative;

    @include transition(color .2s linear);

    &:hover {
      color: #222;
    }

    &.active {
      background: #fff;
      color: #222;
      font-weight: 600;
      cursor: default;

      &:hover {
        color: #222;
      }
    }

    &.lite{
      border-radius: 45px 0 0 45px;

      span {
        position: relative;
        left: 5px;
      }
    }

    &.standard{
      border-left: 0;
      border-radius: 0 45px 45px 0;

      span {
        position: relative;
        left: -5px;
      }
    }

    .discount {
      position: absolute;
      top: -10px;
      font-size: 13px;
    }
  }
}

