.nav-link {
  font-size: 17.5px !important;
}

.navbar-brand {
  .wave-logo{
    height: 90px;
    @media (max-width: 767px){
      height: 60px;
    }
  }
}

.nav-link--rounded{
  border-radius: 0 !important;
}

.navbar-light{
  box-shadow:none !important;

}

/*
.navbar-collapse {
  padding-left: 10px;
  border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 10px;
    font-size: 16.5px;
}
*/
