

.logo {
  width:50px;
  height:50px;
}

.title-row {
  margin-bottom: 4rem;
}

.title-col, .footer-col {
  text-align: center;
}

.footer-row{
  margin-top:2em;
}
/**
 * app-list
 * --------------------------------------------------
 */
.applist-section {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  background-color: #f7fbfe;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .applist-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.applist-row {
  text-align: center;
}
.applist-row > .iconbox {
}
.applist-row > .iconbox:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .applist-row > .iconbox {
    //margin-bottom: 2.25rem;
  }
  .applist-row > .iconbox:nth-child(3), .applist-row > .iconbox:nth-child(4) {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .applist-row > .iconbox {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .applist-row > .iconbox {
    margin-bottom: 0;
  }
}



/**
 * apps
 * --------------------------------------------------
 */
.apps-section {
  padding-top: 4rem;
  padding-bottom: 3rem;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  text-align:center;
}


.apps-section .footer-row {
  margin-top: 3.25rem;
}

@media (min-width: 992px) {
  .apps-section {
    padding-top: 4.25rem;
    padding-bottom: 3.25rem;
  }
}

.apps-section .screen{
  box-shadow: 0 8px 48px 0 rgba(0, 0, 0, 0.08);
  display: inline-block;
}


/**
 * api 
 * ---------------------------------------------------------
 */
.api-section {
  background-color: #f7fbfe;
  padding-top: 4rem;
  padding-bottom: 3rem;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .api-section {
    padding-top: 4.25rem;
    padding-bottom: 3.25rem;
  }
}

@media (min-width: 768px) {
  .api-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (max-width: 767px) {
  .api-left-col {
    margin-bottom: 3rem;
  }
}

.api-media-row {
  margin-top: -1.5rem;
  padding-bottom: 3.25rem; 
}

.api-media-col {
  margin-top: 1.5rem;
}

@media (max-width: 767px) {
  .api-media .media-left {
    display: block;
    float:left;
  }
}

.api-media .media-icon {
  width: 3rem;
  height: 3rem;
  font-size: 2.25rem;
}

@media (min-width: 768px) {
  .api-media .media-left {
    padding-top: 0.25rem;
    padding-right: 1.25rem;
    float:left;
  }
}

.api-img{
  width:60px;
  height:auto;
  margin-right:2em;

}


/**
 * Demo 
 * ---------------------------------------------------------
 */
.demo-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #2a7af3;
  color: #fff;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .demo-section {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem;
  }
}
.demo-section .section-title {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .demo-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (max-width: 767px) {
  .demo-left-col {
    margin-bottom: 1.5rem;
    text-align: center;
  }
}

.demo-right-col {
  text-align: center;
}
@media (min-width: 768px) {
  .demo-right-col {
    text-align: right;
  }
}

/**
 * Service 
 * ---------------------------------------------------------
 */
.service-section {

  padding-top: 4rem;
  padding-bottom: 3rem;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  text-align:center;
}


@media (max-width: 768px) {
  .typed-text{
    padding-top:100px;
    padding-bottom:0px;
    margin-top:4.25rem;
  }

  .get-app{
    display:none
  }

  .how-it-works {
    color: white;
    background-color: #4dc755;
    border-color: #4dc755;

  }

  .apps-section .screen{
    display: none;
  }

  .iconbox-media-img  {
    width: 2.5rem;
    height: 2.5rem;
  }

#home.align-outer{
  padding-bottom: 1rem;

}
}

#home.align-outer{
  height:40rem;

}
