.legal {
   counter-reset: section;


  h2 {
    font-size: 28px !important;
    &:before{
      counter-increment: section;
    }
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  ul {
    font-size: 14px;
    line-height: 29px;

  }

}
