
.hero {
  position: relative;
  top: -1px;

  .hero-content{
    margin: 0 auto;
    position: relative;
    max-width: 100%;
    width: 100%;
    //background: #f5f7f9;

    .hero-customer {
      font-size: 1em;
      line-height: 28px;
      margin: auto;
      margin-bottom: 30px;
      color: #737373;

      @media (max-width: 767px){
        max-width:80%;
      }
    }

    p {
      text-align:center;
    }

    .hero-divider {
      border: none;
      border-bottom: 1px solid #E2E8ED;
      width: 65%;
      margin-bottom: 50px;
      margin-top: 60px;

      @media (max-width: 767px){
        margin-bottom: 30px;
        margin-top: 20px;
      }
    }

    .hero-apps{
      max-width: 500px;
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left:auto;
      margin-right:auto;
      margin-top: 50px;
      margin-bottom: 40px;

      @media (max-width: 767px){
        max-width: 80%;
        margin-top: 30px;
        margin-bottom: 25px;
      }

      img {
        width: 46px;
        margin-right: 20px;
        @media (max-width: 767px){
          width: 40px;
        }
      }

    }

    .hero-wrap-content{
      position:relative;
      text-align: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 995;
      overflow: hidden;

      //background-image: url('/assets/img/hero-background.svg');
      //background-color: #f7f9fa;
      background-position: bottom;
      background-repeat: no-repeat;
      padding-top: 100px;
      padding-bottom: 20px;
      //background-size: 43.33333%;
      //padding-bottom: 17.5%;
      //padding-bottom: 150px;

      @media (max-width: 767px){
        padding-bottom: 0;
        padding-top: 5%;
      }

      section {
        max-width: 83.33333%;
        margin-left:auto;
        margin-right:auto;

        h2 {
          color:#333;
          line-height: 47px;
          font-weight: 500;
          font-size: 40px;
        }

        h3 {
          line-height:1.7em;
          color: #888;
          font-size: 21.5px;
          margin-top: 1.2em; 
          margin-left:auto;
          margin-right:auto;
          max-width: 53.33333%
        }

        a {

          background: #459ce7;
          color: #fff;
          padding: 13px 30px;
          border-radius: 50px;
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1px;
          text-decoration: none;
        }

        @media (max-width: 767px){
          h2 {
            font-size: 30px;
          }

          h3 {
            font-size: 18px;
            max-width:95%;
          }
        }

        a {
          margin-top: 35px;
          margin-bottom: 20px;
        }
      }


      .pic {
        display: block;
        min-width: 100%;
        max-width: none;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background-size: cover;
        background-position: center center;
        opacity: 0.75;

        &:after{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: rgba(82,105,128,.42);
        }
      }
    }
  }
}

